const verge = require('verge');
const throttle = require('throttle-debounce/throttle');

// Variables
const $aside = $('.js-aside'),
	$asideWrapper = $aside.find('.layout__aside-wrapper'),
	$top = $aside.find('.aside__top'),
	$nav = $aside.find('.aside__nav'),
	$logo = $aside.find('.aside__logo'),
	$tools = $aside.find('.tools'),
	$footer = $aside.find('.aside__footer'),
	$lang = $aside.find('.aside__lang--single'),
	$disclosure = $aside.find('#disclosure-links'),
	$copyright = $aside.find('#copyright'),
	$btnToogle = $aside.find('.aside__toggle');

const laptop = 1240,
	tabletHorizontal = 1024,
	tabletVertical = 768,
	mobile = 480;

function toggleContainer(e) {
	e.preventDefault();
	let $wrapperToggle = $aside.find('.aside__container');
	// if ($wrapperToggle.hasClass('aside__container--open')) {
	// 	$wrapperToggle.hide();
	// } else {
	// 	$wrapperToggle.show();
	// }
	$btnToogle.toggleClass('aside__toggle--active');
	$wrapperToggle.toggleClass('aside__container--open');
	$aside.toggleClass('aside-container--opened');
}

global.inWidth = function (maxwidth = 1920, minWidth = 319) {
	let currentWidth = verge.viewportW();
	if (maxwidth >= currentWidth && minWidth < currentWidth) {
		return true;
	} else {
		return false;
	}
};

function showLayoutOverlay() {
	$('.layout__main').addClass('is-overlay');
}

function hideLayoutOverlay() {
	$('.layout__main').removeClass('is-overlay');
}

function laptopLayout() {
	let $wrapperToggle = $aside.find('.aside__container');
	if ($wrapperToggle.length) {
		$aside.removeClass('layout__aside--loaded');
		$nav
			.detach()
			.appendTo($asideWrapper);
		// $tools
		// 	.detach()
		// 	.prependTo($footer);
		$copyright
			.detach()
			.appendTo($footer);
		$footer
			.detach()
			.appendTo($asideWrapper);
		$lang
			.detach()
			.appendTo($footer);
		$wrapperToggle
			.hide()
			.remove();
	}
}

function tabletLayout() {
	let $wrapperToggle = $aside.find('.aside__container');
	if (!$wrapperToggle.length) {
		$wrapperToggle = $(`<div class="aside__container"></div>`);
	}
	// if ($aside.children('.aside__footer').length && !$wrapperToggle.length) {
	$aside.removeClass('layout__aside--loaded');
	$wrapperToggle
		.appendTo($asideWrapper);
	$nav
		.detach()
		.appendTo($wrapperToggle);
	$btnToogle
		.detach()
		.insertAfter($logo);
	// $tools
	// 	.detach()
	// 	.insertAfter($top);
	$lang
		.detach()
		.insertAfter($top);
	$copyright
		.detach()
		.insertAfter($lang);
	$footer
		.detach()
		.appendTo($wrapperToggle);
	if ($nav.length) {
		closeNav();
	}
	// } else if ($aside.children('.tools').length && $wrapperToggle.length) {
	// 	$aside.removeClass('layout__aside--loaded');
	// 	$tools
	// 		.detach()
	// 		.insertAfter($top);
	// 	$copyright
	// 		.detach()
	// 		.insertAfter($lang);
	// 	$btnToogle
	// 		.detach()
	// 		.insertAfter($logo);
	// }
}

function mobileLayout() {
	let $wrapperToggle = $aside.find('.aside__container');

	if ($asideWrapper.children('.aside__footer').length && !$wrapperToggle.length) {
		console.log('AAAA');
		$aside.removeClass('layout__aside--loaded');
		let $wrapperToggle = $(`<div class="aside__container"></div>`);
		$wrapperToggle
			.appendTo($asideWrapper);
		$nav
			.detach()
			.appendTo($wrapperToggle);
		$footer
			.detach()
			.appendTo($wrapperToggle);
		// $tools
		// 	.detach()
		// 	.insertAfter($logo);
		$copyright
			.detach()
			.appendTo($wrapperToggle);
	} else if ($wrapperToggle.children('.aside__footer').length && $wrapperToggle.length) {
		$aside.removeClass('layout__aside--loaded');
		$copyright
			.detach()
			.appendTo($wrapperToggle);
		// $tools
		// 	.detach()
		// 	.insertAfter($logo);
	}
}

$(window).resize(
	throttle(300, function () {
		if (!inWidth(tabletHorizontal)) {
			laptopLayout();
		}
		if (inWidth(tabletHorizontal, tabletVertical)) {
			tabletLayout();
		}
		if (inWidth(tabletVertical)) {
			mobileLayout();
		}
		$aside.addClass('layout__aside--loaded');
	})
);

if (!inWidth(tabletHorizontal)) {
	console.log(1);
	laptopLayout();
}
if (inWidth(tabletHorizontal, tabletVertical)) {
	console.log(2);
	tabletLayout();
}
if (inWidth(tabletVertical)) {
	console.log(3);
	mobileLayout();
}
$aside.addClass('layout__aside--loaded');

function closeContainer() {
	$('.aside__toggle').removeClass('aside__toggle--active');
	$('.aside__container').removeClass('aside__container--open');
}

$(document).on('click', function (e) {
	let $aside = $('.aside');
	if ($aside.length) {
		if (!$aside.is(e.target) && $aside.has(e.target).length === 0) {
			closeContainer();
		}
	}
});

$aside.on('click', '.aside__toggle', toggleContainer);


export {showLayoutOverlay, hideLayoutOverlay, closeContainer};

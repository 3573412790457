const debounce = require('throttle-debounce/debounce');
const throttle = require('throttle-debounce/throttle');
const verge = require('verge');
var templateItems = require('./news-items-list.ejs');
var templateFilter = require('./news-filter.ejs');
var moment = require('moment');

const $news = $('#news'),
	$newsTimeline = $('#news-timeline'),
	$newsItems = $('#news-items'),
	$newsFilter = $('#news-filter'),
	$preloader = $(`<div class="col-default-12 news__preloader"><img src="/images/svg/loader.svg"></div>`),
	$preloaderFilter = $preloader.clone(),
	// $preloaderTimeline = $preloader.clone(),
	$scrollBottom = $(`<div class="col-default-12 news__scroll--bottom"><img src="/images/svg/icon_scroll-bottom.svg"></div>`),
	$scrollTop = $(`<div class="col-default-12 news__scroll--top"><img src="/images/svg/icon_scroll-top.svg"></div>`);

let ajaxUr = '/local/templates/nornik/components/bitrix/news.list/.default/ajax.php';
if ($news.data('ajax-url')) ajaxUr = $news.data('ajax-url')


function createTimestaps() {
	$('.js-filter-timeline__list--detail').find('.js-timeline__link:not(.js-timeline__more)').each(function (i, el) {
		let $link = $(el),
			linkID = $link.data('id'),
			timestampStart,
			timestampEnd;
		if (linkID == 'all') {
			timestampStart = 46800;
		} else if (linkID == 'today') {
			timestampStart = moment().startOf('day').unix();
		} else {
			let split = linkID.split('-');
			timestampStart = moment().subtract(parseInt(split[1]), split[2]).startOf('day').unix();
		}
		timestampEnd = moment().endOf('day').unix();
		$link.attr('data-timestamp-start', timestampStart);
		$link.attr('data-timestamp-end', timestampEnd);
	});
}

function getParamsStr(params) {
	params.source = Array.from(params.source, x => x.attributes['data-filter-id'].value);
	params.subject = Array.from(params.subject, x => x.attributes['data-filter-id'].value);

	return `AJAX_CALL=Y&PAGEN_1=${encodeURIComponent(params.pageNumber)}&source=${encodeURIComponent(params.source)}&subject=${encodeURIComponent(params.subject)}&search=${encodeURIComponent(params.search)}&dateStart=${encodeURIComponent(params.dateStart)}&dateEnd=${encodeURIComponent(params.dateEnd)}`;
}

function getCurrentParams() {
	return {
		pageNumber: parseInt($news.attr('data-page-current')),
		source: $news
			.find('[data-filter-container="source"]')
			.find('.filter-item__filter-link--active'),
		subject: $news
			.find('[data-filter-container="subject"]')
			.find('.filter-item__filter-link--active'),
		search: $('#news-search').val(),
		dateStart: parseInt($news.attr('data-page-start')),
		dateEnd: parseInt($news.attr('data-page-end'))
	};
}

function setParams(current, total, dateStart, dateEnd) {
	$news
		.attr('data-page-current', current)
		.attr('data-page-total', total)
		.attr('data-page-start', dateStart)
		.attr('data-page-end', dateEnd);

	// console.log(dateStart);
	// console.log(dateEnd);

	let $actualEl = $newsTimeline.find($(`[data-timestamp-start="${dateStart}"]`)),
		$activeEl = $newsTimeline.find($('.filter-timeline__link--active'));

	if (!$actualEl.is(':visible')) {
		if ($actualEl.hasClass('filter-timeline__link--years')) {
			let	$openedYear = $news.find('#filter-timeline--years')
					.find('.filter-timeline__link.filter-timeline__link--open'),
				$openedYearUL = $news.find('#filter-timeline--years')
					.find('.filter-timeline__list--2.filter-timeline__list--open');

			if ($openedYearUL.length) {
				$openedYear
					.removeClass('filter-timeline__link--open');
				$openedYearUL
					.removeClass('filter-timeline__list--open');
			}

			$actualEl
				.closest('.filter-timeline__list--2')
				.siblings('.js-timeline__link--years')
				.addClass('filter-timeline__link--open');

			$actualEl
				.closest('.filter-timeline__list--2')
				.addClass('filter-timeline__list--open');

			if (!$actualEl.is(':visible')) {
				$('.js-timeline__more').trigger('click');
			}
		}
	}

	if (!($actualEl == $activeEl)) {
		$activeEl.removeClass('filter-timeline__link--active');
		$actualEl.addClass('filter-timeline__link--active');
	}

	if (!$actualEl.length) {
		$actualEl = $newsTimeline.find($(`.js-timeline__link--all`));
	}

	// $preloaderTimeline.remove();

	$newsFilter.find('.filter__group').each(function () {
		checkAllActiveFilter($(this));
	});

	// console.log(`Установили следующие данные:
	// 	Текущая стр. = ${current}
	// 	Всего стр. = ${total}
	// 	Дата начала выборки = ${dateStart} = ${moment.unix(dateStart).format('YYYY-MM-DD HH:mm')}
	// 	Дата конца выборки = ${dateEnd} = ${moment.unix(dateEnd).format('YYYY-MM-DD HH:mm')}
	// `);
}

global.removeParams = function (array) {
	let $oldParams = getCurrentParams(),
		arrOldParams = $.map($oldParams, (v, i) => {
			return i;
		});

	let arrayParams = arrOldParams.concat(array);

	var url = document.location.href,
		urlparts = url.split('?');

	if (urlparts.length >= 2)	{
		var urlBase = urlparts.shift(),
			queryString = urlparts.join('?'),
			pars = queryString.split(/[&;]/g);

		$.each(arrayParams, function (i, v) {
			let prefix = encodeURIComponent(arrayParams[i]) + '=';

			for (var i = pars.length; i-- > 0;) {
				if (pars[i].lastIndexOf(prefix, 0) !== -1) {
					pars.splice(i, 1);
				}
			}

			if (pars.length == 0) {
				url = urlBase;
			} else {
				url = urlBase + '?' + pars.join('&');
			}

			return url;
		});
		window.history.pushState('', document.title, url);
	}

	return url;
};

function getNewDateRange(currentTimestamp, direction) {
	let arrayRange,
		dateStart,
		dateEnd,
		$el = $(`[data-timestamp-start="${currentTimestamp}"]`);

	if (direction == 'next') {
		// next
		var $nextEl = $el.closest('.filter-timeline__item').next();
		if (!$nextEl.length && $el.hasClass('filter-timeline__link--years')) {
			$nextEl = $el
				.closest('.filter-timeline__list--2')
				.closest('.filter-timeline__item')
				.next()
				.find('.filter-timeline__link--years')
				.first();
			dateStart = parseInt($nextEl.attr('data-timestamp-start'));
			dateEnd = parseInt($nextEl.attr('data-timestamp-end'));
		} else {
			$nextEl = $nextEl.find('.filter-timeline__link');
			dateStart = $nextEl.attr('data-timestamp-start');
			if (!($nextEl.attr('data-timestamp-end') === undefined)) {
				dateEnd = parseInt($nextEl.attr('data-timestamp-end'));
			} else if (currentTimestamp == 46800) {
				dateEnd = moment().endOf('day').unix();
			} else {
				dateEnd = parseInt(currentTimestamp) - 1;
			}
		}

	} else if (direction == 'prev') {
		// prev
		var $prevEl = $el.closest('.filter-timeline__item').prev();
		if (!$prevEl.length && $el.hasClass('filter-timeline__link--years')) {
			$prevEl = $el
				.closest('.filter-timeline__list--2')
				.closest('.filter-timeline__item')
				.prev()
				.find('.filter-timeline__link--years')
				.last();
			dateStart = parseInt($prevEl.attr('data-timestamp-start'));
			dateEnd = parseInt($prevEl.attr('data-timestamp-end'));
		} else {
			$prevEl = $prevEl.find('.filter-timeline__link');
			dateStart = $prevEl.attr('data-timestamp-start');
			if (!($prevEl.attr('data-timestamp-end') === undefined)) {
				dateEnd = parseInt($prevEl.attr('data-timestamp-end'));
			} else if (currentTimestamp == moment().startOf('day').unix()) {
				dateEnd = moment().endOf('day').unix();
			} else if (currentTimestamp == moment().subtract(1, 'days').startOf('day').unix()) {
				dateEnd = moment().endOf('day').unix();
			} else {
				dateEnd = $el.closest('.filter-timeline__item')
					.prev()
					.prev()
					.find('.filter-timeline__link')
					.attr('data-timestamp-start');
				dateEnd = parseInt(dateEnd) - 1;
			}
		}

	} else {
		// click
		dateStart = currentTimestamp;
		// if ((currentTimestamp == 46800) || (currentTimestamp == moment().startOf('day').unix())) {
		dateEnd = moment().endOf('day').unix();
		// } else {
		// 	dateEnd = $el
		// 		.closest('.filter-timeline__item')
		// 		.prev()
		// 		.find('.filter-timeline__link')
		// 		.attr('data-timestamp-start');
		// 	dateEnd = parseInt(dateEnd) - 1;
		// }
		dateEnd = parseInt(dateEnd);
	}
	dateStart = parseInt(dateStart);
	return {dateStart, dateEnd};
}

global.loadNewsFirst = function () {
	getJson(ajaxUr)
		.then(function (data) {
			let json = arNews;
			// console.log(json);

			$newsFilter
				.find($preloaderFilter)
				.remove();

			$newsItems
				.find($preloader)
				.remove();

			$newsItems
				.html(templateItems(json));

			// $newsFilter
			// 	.html(templateFilter(json));
			initValidationForms($newsFilter.find('.js-news-search'));

			// console.log('Построили контент первый раз');
			return json;
		})
		.then(function (json) {
			if (typeof json.date != 'undefined') {
				if (typeof json.date.dateStart != 'undefined') {
					var customDateStart = json.date.dateStart;
				} else {
					var customDateStart = moment().startOf('day').unix();
				}
				if (typeof json.date.dateEnd != 'undefined') {
					var customDateEnd = json.date.dateEnd;
				} else {
					var customDateEnd = moment().endOf('day').unix();
				}
			} else {
				var customDateStart = 46800;
				var customDateEnd = moment().endOf('day').unix();
			}

			if (parseInt(json.page.current) < parseInt(json.page.total)) {
				if (customDateStart == 46800) {
					$scrollBottom
						.appendTo($newsItems);
					$scrollTop
						.remove();
				} else {
					$scrollBottom
						.appendTo($newsItems);
					$scrollTop
						.prependTo($newsItems);
				}
			}

			return setParams(json.page.current, json.page.total, customDateStart, customDateEnd);
		})
		.catch(function (error) {
			console.error('Error: ' + error);
			showNotyfications(error, {type: 'error'});
		});
};

global.loadNewsAjax = function (state) {
	if (!state) {
		var direction = 'new';
	} else {
		var direction = state;
	}

	let $this = $(this),
		timestamp = $this.attr('data-timestamp-start');
	let totalPages = $news.attr('data-page-total');

	if ($news.attr('data-page-start') == 46800 && direction == 'prev') {
		return false;
	}

	let params = getCurrentParams();
	// console.log(params);

	if (direction == 'next') {
		if (params.pageNumber == totalPages || totalPages == 0) {
			let range = getNewDateRange(params.dateStart, direction);
			params.dateStart = range.dateStart;
			params.dateEnd = range.dateEnd;
			params.pageNumber = 1;
		} else {
			params.pageNumber = parseInt(params.pageNumber) + 1;
		}
	} else if (direction == 'prev') {
		if (params.pageNumber == 1) {
			let range = getNewDateRange(params.dateStart, direction);
			params.dateStart = range.dateStart;
			params.dateEnd = range.dateEnd;
			params.pageNumber = 1;
		} else {
			// params.pageNumber = parseInt(params.pageNumber) - 1;
			// пока так :(
			let range = getNewDateRange(params.dateStart, direction);
			params.dateStart = range.dateStart;
			params.dateEnd = range.dateEnd;
			params.pageNumber = 1;
		}
	} else if ($this.attr('data-timestamp-end') && $this.attr('data-timestamp-start')) {
		params.dateStart = parseInt($this.attr('data-timestamp-start'));
		params.dateEnd = parseInt($this.attr('data-timestamp-end'));
		params.pageNumber = 1;
	} else if ($this.attr('data-timestamp-start')) {
		let range = getNewDateRange(timestamp);
		params.dateStart = range.dateStart;
		params.dateEnd = range.dateEnd;
		params.pageNumber = 1;
	} else if ($this.attr('data-filter-id')) {
		params.pageNumber = 1;
	}

	let paramsStr = getParamsStr(params);
	// console.log(`---------------------------------------------------------------------`);
	// console.log(`Передаю следующую строку в параметрах: ${paramsStr}`);

	removeParams(['tags', 'PAGEN_1', 'AJAX_CALL']);
	getJson(`${ajaxUr}?${paramsStr}`)
		.then(function (data) {
			let json = data;
			json = JSON.parse(JSON.stringify(eval('(' + json + ')')));

			// console.log(`Пришел ответ:`);
			// console.log(json);

			if (!(params.pageNumber == 1)) {
				if (direction == 'next') {
					$newsItems.append(templateItems(json));
				} else if (direction == 'prev') {
					$newsItems.prepend(templateItems(json));
				}
			} else {
				$newsItems.html(templateItems(json));
			}
			return json;
		})
		.then(function (json) {
			$preloader
				.remove();
			setParams(json.page.current, json.page.total, json.date.dateStart, json.date.dateEnd);

			if (parseInt(json.page.current) < parseInt($news.attr('data-page-total'))) {
				if (json.date.dateStart == 46800 || (json.date.dateStart == $('.filter-timeline__link--years').first().attr('data-timestamp-start'))) {
					$scrollBottom
						.appendTo($newsItems);
					$scrollTop
						.remove();
				} else if ((json.date.dateStart == moment().subtract(2, 'month').startOf('day').unix() || json.date.dateStart == $('.filter-timeline__link--years').last().attr('data-timestamp-start')) && (json.page.current >= $news.attr('data-page-total'))) {
					$scrollTop
						.prependTo($newsItems);
					$scrollBottom
						.remove();
				} else {
					$scrollBottom
						.appendTo($newsItems);
					$scrollTop
						.prependTo($newsItems);
				}
			} else {
				$scrollBottom
					.remove();
			}
		})
		.catch(function (error) {
			console.error('Error: ' + error);
			showNotyfications(error, {type: 'error'});
		});
};

if ($news.length) {
	$newsFilter.html($preloaderFilter);
	// $newsTimeline.prepend($preloaderTimeline);
	$newsItems.html($preloader);
	createTimestaps();
	require('./news-timeline.js');
}

$(document).on('click', '#news .js-filter__link', function (e) {
	let $this = $(this),
		$container = $this.closest('.filter__group');

	if ($this.hasClass('filter-item__filter-link--disabled')) {
		return false;
	}

	if ($this.hasClass('js-filter__all')) {
		$container
			.find('.filter-item__filter-link:not(.js-filter__all)')
			.addClass('filter-item__filter-link--active');
		$this.addClass('filter-item__filter-link--disabled');
	} else {
		$(this).toggleClass('filter-item__filter-link--active');
		checkAllActiveFilter($container);
	}
});

$(document).on('click', '#news .js-timeline__link, #news .js-filter__link', function (e) {
	let $this = $(this);
	if ($this.attr('data-timestamp-start') || $this.attr('data-filter-id')) {
		$newsItems.html($preloader);
	}
});

$(document).on('click', '#news .js-timeline__link, #news .js-filter__link', debounce(1000, loadNewsAjax));

//
//
// scroll logic

function goLoadPrev() {
	if ($news.attr('data-page-start') == 46800 || ($news.attr('data-page-start') == $('.filter-timeline__link--years').first().attr('data-timestamp-start'))) {
		return false;
	}
	$scrollTop.remove();
	$newsItems.prepend($preloader);
	loadNewsAjax('prev');
}

function goLoadNext() {
	if (($news.attr('data-page-start') == moment().subtract(2, 'month').startOf('day').unix() || $news.attr('data-page-start') == $('.filter-timeline__link--years').last().attr('data-timestamp-start')) && ($news.attr('data-page-current') >= $news.attr('data-page-total'))) {
		return false;
	}

	$scrollBottom.remove();
	$newsItems.append($preloader);
	loadNewsAjax('next');
}

function checkScroll(e) {
	let inViewportBottom = verge.inViewport($('.news__scroll--bottom'), -16);
	let inViewportTop = verge.inViewport($('.news__scroll--top'), 16);
	let startScrollTop = $(window).scrollTop();

	if (e.type == 'touchmove') {

		let currentY = e.originalEvent.touches[0].clientY;

		if (startY < currentY) {
			if (inViewportTop) {
				e.preventDefault();
				e.stopPropagation();
				goLoadPrev();
			}
		} else {
			if (inViewportBottom) {
				e.preventDefault();
				e.stopPropagation();
				goLoadNext();
			}
		}
		return false;

	} else if (e.type == 'mousewheel' || e.type == 'DOMMouseScroll') {

		if (e.originalEvent.wheelDelta > 0 || e.originalEvent.detail < 0) {
			if (inViewportTop) {
				e.preventDefault();
				e.stopPropagation();
				goLoadPrev();
			}
		} else {
			if (inViewportBottom) {
				e.preventDefault();
				e.stopPropagation();
				goLoadNext();
			}
		}
		return false;

	}
	//  else if (e.type == "scroll") {
	//
	// 	let currentScroll = $(window).scrollTop();
	//
	// 	if (currentScroll < startScrollTop) {
	// 		if (inViewportTop) {
	// 			e.preventDefault();
	// 			e.stopPropagation();
	// 			goLoadPrev();
	// 		}
	// 	} else {
	// 		if (inViewportBottom) {
	// 			e.preventDefault();
	// 			e.stopPropagation();
	// 			goLoadNext()
	// 		}
	// 	}
	// 	return false;

	// }

}

var startY;
$(document).bind('touchstart', function (e) {
	startY = e.originalEvent.touches[0].clientY;
});
// if ($news.length) {
//	 $(window).on('scroll', (e) => debounce(1000, checkScroll(e)));
$(document).on('touchmove scroll mousewheel DOMMouseScroll', throttle(250, function (e) {
	checkScroll(e);
}));

$(document).on('click', '.news__scroll--top > img', goLoadPrev);
$(document).on('click', '.news__scroll--bottom > img', goLoadNext);

// }

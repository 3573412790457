const verge = require('verge');
const throttle = require('throttle-debounce/throttle');

const $nav = $('.js-nav'),
	$aside = $('.js-aside'),
	$navToggle = $nav.find('.nav__toggle'),
	$navLink = $nav.find('.nav__link');

function updateOffset() {
	let navOffsetTop = verge.rectangle($nav.find('.nav__list--1 > .nav__item:eq(0)')).top;

	$('.nav__list--2, .nav__area').css({
		'padding-top': navOffsetTop + 'px'
	});
}

function checkOpenedThirdLevel() {
	let $opened3Lvl = $('.nav__link--3.nav__link--open');

	if ($opened3Lvl.length) {
		$opened3Lvl
			.closest('.nav__list--3')
			.addClass('nav__list--open');
		$opened3Lvl
			.closest('.nav__list--3')
			.show();
		$opened3Lvl
			.closest('.nav__list--3')
			.siblings('.nav__link--parent')
			.addClass('nav__link--active');
	}
}

global.closeNav = function () {
	$('.nav__list--open')
		.hide()
		.removeClass('nav__list--open');
	$('.nav__link--active')
		.removeClass('nav__link--active');
	$('.nav__area--open')
		.hide()
		.removeClass('nav__area--open');
	$('.aside').removeClass('aside--opened');
};

function toggleChild(e) {
	// if ($(e.target).hasClass('nav__text') && $(e.target).parent().hasClass('nav__link--2')) return true;
	e.preventDefault();

	let $this = $(this),
		$navListChild = $this.siblings('.nav__list');
	if ($navListChild.hasClass('nav__list--open')) {
		$navListChild
			.hide();
	} else {
		$this
			.closest('.nav__list')
			.find('.nav__list--open')
			.hide()
			.removeClass('nav__list--open');
		$navListChild
			.show();
		$('.aside').addClass('aside--opened');

		if ($this.hasClass('nav__link--1')) {
			checkOpenedThirdLevel();
		}
	}

	if ($this.hasClass('nav__link--active')) {
		$this
			.removeClass('nav__link--active');
		$navListChild
			.find('.nav__link--active')
			.removeClass('nav__link--active');
		if (!$('.nav__link--active').length) {
			$('.aside').removeClass('aside--opened');
		}
	} else {
		$this
			.parent()
			.siblings()
			.children('.nav__link--active')
			.removeClass('nav__link--active');
		$this
			.parent()
			.siblings()
			.find('.nav__link--active')
			.removeClass('nav__link--active');
		$this
			.addClass('nav__link--active');
		$('.aside').addClass('aside--opened');

		if ($this.hasClass('nav__link--1')) {
			checkOpenedThirdLevel();
		}
	}
	$navListChild
		.toggleClass('nav__list--open');
}

function toggleArea() {
	let $this = $(this),
		$navListChild = $this.siblings('.nav__list'),
		$navArea = $this.siblings('.nav__area');
	if ($navListChild.hasClass('nav__list--open')) {
		$navArea.hide();
	} else {
		$this
			.closest('.nav')
			.find('.nav__area--open')
			.hide()
			.removeClass('nav__area--open');
		$navArea.show();
	}
	$navArea.toggleClass('nav__area--open');
}

if ($nav.length) {
	updateOffset();
	checkOpenedThirdLevel();
}

$nav.on('click', '.nav__link--1', toggleArea);
$nav.on('click', '.nav__link--1', updateOffset);
$nav.on('click', '.nav__link--parent', toggleChild);

$(window).resize(
	throttle(300, function () {
		updateOffset();
	})
);

$nav.on('click', '.nav__link', function (e) {
	if (!$(this).hasClass('nav__link--parent')) {
		closeNav();
	}
});

$(document).on('click', '.js-nav-area__close', closeNav);
$(document).on('click', function (e) {
	if (!$nav.is(e.target) && $nav.has(e.target).length === 0 && !$aside.is(e.target) && $aside.has(e.target).length === 0) {
		closeNav();
	}
});
